<template>
  <ion-page id="page-content">
    <ion-modal @didDismiss="showChangePwdTip = false" :is-open="showChangePwdTip" css-class="tcc-modal" :animated="false">
      <div class="model-box">
        <div class="ion-page">
          <div class="modal-content friend-modal">
            <p class="font-18 font-b main-color">Password has been changed successfully, Please login again</p>
            <div class="btn-box" style="margin-top: 30px">
              <button style="background-color: #223b86; color: #ffffff" class="btn" @click="nextEvent">Confirm</button>
            </div>
          </div>
        </div>
      </div>
    </ion-modal>
    <ion-content :fullscreen="true">
      <topbar :bigtitle="true" :class="{ 'letter-ios-14': $store.state.deviceType == 1 }" title="Change Password"
        :showBack="true"></topbar>
      <div class="pc-box big">
        <div class="pc-min-content">
          <div class="content">
            <div class="title-PC" v-if="$store.state.isPC">Change Password</div>
            <div class="form-item p-b-20">
              <label>Current Password</label>
              <div class="input-box password">
                <input :type="ispassword ? 'password' : 'text'" maxlength="15" v-model="oldPassword"
                  placeholder="Current Password" />
                <i class="icon-eye" @click="ispassword = false" v-if="ispassword"></i>
                <i class="icon-eye-blocked" @click="ispassword = true" v-else></i>
              </div>
            </div>
            <div class="form-item p-b-20">
              <label>New Password</label>
              <div class="input-box password">
                <input :type="ispassword2 ? 'password' : 'text'" maxlength="15" v-model="newPassword"
                  placeholder="New Password" @blur="confirmPwdEvent(0)" @input="inputCheckEvent(0)" />
                <i class="icon-eye" @click="ispassword2 = false" v-if="ispassword2"></i>
                <i class="icon-eye-blocked" @click="ispassword2 = true" v-else></i>
              </div>
              <p class="tip" :class="{ red: errorPassword }">8-15 characters with letters, numbers and special characters,
                case sensitive</p>
            </div>
            <div class="form-item p-b-20">
              <label>Confirm Password</label>
              <div class="input-box password">
                <input :type="ispassword3 ? 'password' : 'text'" maxlength="15" v-model="confirmNewPassword"
                  placeholder="Confirm New Password" @blur="confirmPwdEvent(1)" @input="inputCheckEvent(1)" />
                <i class="icon-eye" @click="ispassword3 = false" v-if="ispassword3"></i>
                <i class="icon-eye-blocked" @click="ispassword3 = true" v-else></i>
              </div>
              <p class="tip red" v-if="errorConfirm">Passwords do not match</p>
              <!-- <p class="font-14 Helveticaneue gery-color m-t-5">8-15 characters with letters, numbers and special characters, case sensitive</p> -->
              <p class="tip Helveticaneue" style="margin-top: 15px; text-align: center">
                <span class="main-color" @click="jumpForgot()">Forgot Password?</span>
              </p>
            </div>
            <div class="pad-t-20">
              <ion-button expand="block" :disabled="isConfirm" @click="confirm">Save</ion-button>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonButton, IonModal } from "@ionic/vue";
import topbar from "@/components/topbar.vue";
import config from "../config";
export default {
  name: "changePassword",
  mixins: [config.globalMixin],
  components: { IonContent, IonPage, topbar, IonButton, IonModal },
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      ispassword: true,
      ispassword2: true,
      ispassword3: true,
      errorConfirm: false,
      isConfirm: true,
      errorPassword: false,
      showChangePwdTip: false,
    };
  },
  methods: {
    async confirm() {
      const objdata = this.getAuthObj();
      objdata.oldPassword = this.oldPassword;
      objdata.newPassword = this.newPassword;
      objdata.confirmNewPassword = this.confirmNewPassword;
      objdata.apiUrl = this.apiConfig.changeUserPassword;
      const data = await this.$store.dispatch("apiPutEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.sendGAEvent("Click", "Change Password", "Save");
      //   this.showChangePwdTip = true;
      setTimeout(() => {
        this.nextEvent();
      }, 500);
      this.successToastLT("Password has been \n changed successfully, \n Please login again");
    },
    jumpForgot() {
      this.sendGAEvent("Click", "Change Password", "Forgot password");
      if (this.$store.state.form.userInfo.isPhoneValidation > 0) {
        this.$router.push("/forgotPassword/2");
      } else if (this.$store.state.form.userInfo.isEmailValidation > 0) {
        this.$router.push("/forgotPassword/1");
      }
    },
    inputCheckEvent(type) {
      this.errorConfirm = false;
      this.errorPassword = false;
      if (this.confirmNewPassword != '' && this.newPassword != '' && this.confirmNewPassword == this.newPassword) {
        this.confirmPwdEvent(type);
      }
    },
    nextEvent() {
      //   this.showChangePwdTip = false;
      this.$store.state.form.token = "";
      this.setStorageEvent();
      this.$router.replace({
        path: "/login",
      });
    },

    confirmPwdEvent(type) {
      if (type == 0) {
        if (!this.$store.state.passwordPatrn.test(this.newPassword)) {
          this.errorPassword = true;
          return
        }
      } else {
        if (this.confirmNewPassword == '' || this.confirmNewPassword == null) {
          return
        }
        if (this.newPassword != this.confirmNewPassword) {
          this.errorConfirm = true;
          return
        }
      }
      this.errorConfirm = false;
      this.errorPassword = false;
      if (this.confirmNewPassword != "" && this.confirmNewPassword == this.newPassword && this.oldPassword != '') {
        this.isConfirm = false;
      } else {
        this.isConfirm = true;
      }

    },
  },
  ionViewWillEnter() {
    this.oldPassword = "";
    this.newPassword = "";
    this.confirmNewPassword = "";
    this.$store.state.exitApp = false;

    this.sendGAPageView("Change Password");
  },
};
</script>
